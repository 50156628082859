define("frontend/routes/artist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.store.query('artist', {
        slug: params.artist_slug,
        include: 'albums'
      });
    },

    setupController(controller, model) {
      controller.set('artist', model.get('firstObject'));

      this._super(controller, model);
    }

  });

  _exports.default = _default;
});