define("frontend/components/newsletter-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isFormInvalid: true,
    alreadyToggled: false,
    isDataLoading: false,
    dataSuccessfullySubmitted: false,
    showDialog: false,
    isSubmitButtonDisabled: Ember.computed('isFormInvalid', 'isDataLoading', 'dataSuccessfullySubmitted', function () {
      return this.isFormInvalid || this.isDataLoading || this.dataSuccessfullySubmitted;
    }),
    isFormDisabled: Ember.computed('isDataLoading', 'dataSuccessfullySubmitted', function () {
      return this.isDataLoading || this.dataSuccessfullySubmitted;
    }),
    actions: {
      toggleFormValidity() {
        // this.toggleProperty('isFormInvalid');
        let customer = this.model;

        if (customer.validate()) {
          // Ember.Logger.info('customer validated!!');
          this.set('isFormInvalid', false);
        } else {
          // Ember.Logger.info(customer.get('errors'));
          this.set('isFormInvalid', true);
        }
      },

      keyUp() {
        this.send('toggleFormValidity');
      },

      toggleAgreement() {
        this.set('alreadyToggled', true);
        this.toggleProperty('model.isAgreementFulfilled');
        this.send('toggleFormValidity');
      },

      save() {
        let customer = this.model;

        if (customer.validate()) {
          this.set('isDataLoading', true);
          let comp = this;
          customer.save().then(function () {
            // Ember.Logger.info('save ok');
            comp.set('isDataLoading', false);
            comp.set('dataSuccessfullySubmitted', true);
            comp.set('showDialog', true);
          }).catch(function () {
            // Ember.Logger.info('save error');
            comp.set('isDataLoading', false);
            comp.set('dataSuccessfullySubmitted', false);
            comp.set('showDialog', true);
            comp.set('showError', true);
          });
        }
      },

      closeDialog() {
        this.set('showDialog', false);
      }

    }
  });

  _exports.default = _default;
});