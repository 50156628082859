define("frontend/models/design-asset", ["exports", "@ember-data/model", "frontend/config/environment"], function (_exports, _model, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    title: (0, _model.attr)(),
    position: (0, _model.attr)(),
    content: (0, _model.attr)(),
    asset: (0, _model.attr)(),
    videoUrl: (0, _model.attr)(),
    youtubeId: (0, _model.attr)(),
    design: (0, _model.belongsTo)('design'),
    assetUrl: Ember.computed('asset', function () {
      return _environment.default.APP.apiHost + '/images/design_assets/' + this.asset;
    })
  });

  _exports.default = _default;
});