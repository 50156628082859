define("frontend/templates/components/hero-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p+YDLrou",
    "block": "{\"symbols\":[\"car\",\"hero\",\"&default\"],\"statements\":[[5,\"bs-carousel\",[],[[\"@autoPlay\"],[true]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[\"slide\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"a\",true],[11,\"href\",[23,2,[\"url\"]]],[8],[0,\"\\n        \"],[7,\"img\",true],[11,\"alt\",[23,2,[\"fuegoId\"]]],[11,\"src\",[23,2,[\"heroUrl\"]]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]}],[0,\"\\n\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/hero-banner.hbs"
    }
  });

  _exports.default = _default;
});