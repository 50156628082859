define("frontend/services/site-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    filter(param) {
      if (param !== '') {
        return Ember.RSVP.hash({
          albums: this.store.query('album', {
            title: param
          }),
          albumsByArtist: this.store.query('album', {
            'artist.name': param
          }),
          books: this.store.query('book', {
            title: param
          }),
          booksByAuthor: this.store.query('book', {
            'author.name': param
          }),
          news: this.store.query('news', {
            title: param
          })
        }).then(results => {
          return {
            query: param,
            results: results
          };
        });
      } else {
        return Ember.RSVP.hash({
          albums: this.store.findAll('album'),
          books: this.store.findAll('book'),
          news: this.store.findAll('news')
        }).then(results => {
          return {
            query: param,
            results: results
          };
        });
      }
    }

  });

  _exports.default = _default;
});