define("frontend/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    fullScreen: Ember.inject.service(),
    isSearch: false,
    // eslint-disable-next-line ember/no-observers
    currentPathObserver: Ember.observer('target.url', function () {
      this.set('isSearch', false);
    }),
    actions: {
      toggleSearch() {
        this.toggleProperty('isSearch');
      }

    }
  });

  _exports.default = _default;
});