define("frontend/templates/newsletter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t9uKwJio",
    "block": "{\"symbols\":[\"card\",\"title\",\"text\"],\"statements\":[[7,\"h2\",true],[8],[0,\"FUEGO Newsletter\"],[9],[0,\"\\n\\n\"],[5,\"paper-card\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"title\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"text\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"headline\"]],[],[[],[]],{\"statements\":[[0,\"E-Mail-Abonnement jetzt kostenlos bestellen\"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\\n  \"],[6,[23,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"newsletter-form\",[],[[\"@model\"],[[22,\"model\"]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/newsletter.hbs"
    }
  });

  _exports.default = _default;
});