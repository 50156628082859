define("frontend/transitions/focus-after", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(nextTransitionName, options, ...rest) {
    (true && !('string' === typeof nextTransitionName) && Ember.assert("You must provide a transition name as the first argument to focusAfter. Example: this.use('focusAfter', 'toLeft')", 'string' === typeof nextTransitionName));
    let nextTransition = this.lookup(nextTransitionName);

    if (!options) {
      options = {};
    }

    (true && !('object' === typeof options) && Ember.assert('The second argument to focusAfter must be an object', 'object' === typeof options)); // set scroll options via: this.use('focusAfter', 'ToLeft', {selector: '#email'})

    options = Ember.assign({
      selector: 'input, textarea'
    }, options); // additional args can be passed through after the scroll options object
    // like so: this.use('focusAfter', 'moveOver', {selector: '#email'}, 'x', -1);

    return nextTransition.apply(this, rest).then(() => {
      if (this.newElement) {
        this.newElement.find(options.selector).first().focus();
      }
    });
  }
});