define("frontend/routes/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        albumsByArtist: this.store.query('album', {
          'artist.name': params.q
        }),
        albumsByTitle: this.store.query('album', {
          title: params.q
        }),
        albumsByDescription: this.store.query('album', {
          description: params.q
        }),
        albumsByGenre: this.store.query('album', {
          'genres.name': params.q
        }),
        booksByAuthor: this.store.query('book', {
          'author.name': params.q
        }),
        booksByTitle: this.store.query('book', {
          title: params.q
        }),
        booksBySubtitle: this.store.query('book', {
          subtitle: params.q
        }),
        booksByDescription: this.store.query('book', {
          description: params.q
        }),
        booksByGenre: this.store.query('book', {
          'genres.name': params.q
        }),
        newsByTitle: this.store.query('news', {
          title: params.q
        }),
        newsBySubtitle: this.store.query('news', {
          subtitle: params.q
        }),
        newsByCategory: this.store.query('news', {
          category: params.q
        }),
        newsByContent: this.store.query('news', {
          content: params.q
        })
      });
    }

  });

  _exports.default = _default;
});