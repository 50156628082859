define("frontend/templates/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+AP0qIFx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"loading-spinner\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"rect1\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"rect2\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"rect3\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"rect4\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"rect5\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/loading-spinner.hbs"
    }
  });

  _exports.default = _default;
});