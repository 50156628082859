define("frontend/locales/de/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    // "some.translation.key": "Text for some.translation.key",
    //
    // "a": {
    //   "nested": {
    //     "key": "Text for a.nested.key"
    //   }
    // },
    //
    // "key.with.interpolation": "Text with {{anInterpolation}}"
    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "is invalid",
      confirmation: "doesn't match {{attribute}}",
      accepted: "muss akzeptiert werden",
      empty: "can't be empty",
      blank: "darf nicht leer sein",
      present: "must be blank",
      tooLong: "is too long (maximum is {{count}} characters)",
      tooShort: "is too short (minimum is {{count}} characters)",
      wrongLength: "is the wrong length (should be {{count}} characters)",
      notANumber: "is not a number",
      notAnInteger: "must be an integer",
      greaterThan: "must be greater than {{count}}",
      greaterThanOrEqualTo: "must be greater than or equal to {{count}}",
      equalTo: "must be equal to {{count}}",
      lessThan: "must be less than {{count}}",
      lessThanOrEqualTo: "must be less than or equal to {{count}}",
      otherThan: "must be other than {{count}}",
      odd: "must be odd",
      even: "must be even"
    }
  };
  _exports.default = _default;
});