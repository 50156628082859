define("frontend/helpers/format-typeid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatTypeid = formatTypeid;
  _exports.default = void 0;

  function formatTypeid([value]) {
    let type = 'Sonstiges';

    if (value === 6) {
      type = 'Single-Track';
    } else if (value === 7) {
      type = 'Maxi / EP';
    } else if (value === 8) {
      type = 'Album';
    }

    return type;
  }

  var _default = Ember.Helper.helper(formatTypeid);

  _exports.default = _default;
});