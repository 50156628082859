define("frontend/models/album-track", ["exports", "@ember-data/model", "frontend/config/environment"], function (_exports, _model, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    cdset: (0, _model.attr)(),
    number: (0, _model.attr)(),
    title: (0, _model.attr)(),
    length: (0, _model.attr)(),
    text: (0, _model.attr)(),
    music: (0, _model.attr)(),
    publisher: (0, _model.attr)(),
    producer: (0, _model.attr)(),
    url: (0, _model.attr)(),
    album: (0, _model.belongsTo)('album'),
    name: Ember.computed('title', function () {
      return this.title;
    }),
    coverUrl: Ember.computed('album.coverUrl', function () {
      return this.get('album.coverUrl');
    }),
    audioUrl: Ember.computed('number', 'album.cleanedUpFuegoId', function () {
      let n = this.number;
      let trackNumber = n < 10 ? "0" + n : n;
      return _environment.default.APP.assetPrefix + '/music_tracks/' + this.get('album.cleanedUpFuegoId') + '/' + trackNumber + '.mp3';
    }),
    numberIndex: Ember.computed('number', function () {
      return this.number - 1;
    }),
    artist: Ember.computed('album.artist.name', function () {
      return this.get('album.artist.name');
    }),
    albumName: Ember.computed('album.title', function () {
      return this.get('album.title');
    })
  });

  _exports.default = _default;
});