define("frontend/templates/artists/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "49Uf1pz+",
    "block": "{\"symbols\":[\"artist\"],\"statements\":[[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[5,\"creator-card-list\",[],[[\"@model\",\"@creatorType\"],[[23,1,[]],\"artist\"]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[5,\"infinity-loader\",[],[[\"@infinityModel\"],[[22,\"model\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/artists/index.hbs"
    }
  });

  _exports.default = _default;
});