define("frontend/models/design", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    title: (0, _model.attr)(),
    subtitle: (0, _model.attr)(),
    categoryName: (0, _model.attr)(),
    content: (0, _model.attr)(),
    assets: (0, _model.hasMany)('design-asset'),
    assetsSorting: Object.freeze(['position']),
    sortedAssets: Ember.computed.sort('assets', 'assetsSorting'),
    iconName: Ember.computed('categoryName', function () {
      return this.categoryName.split(' ').pop().slice(3);
    }),
    iconPrefix: Ember.computed('categoryName', function () {
      return this.categoryName.split(' ').reverse().pop();
    })
  });

  _exports.default = _default;
});