define("frontend/router", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('news', {
      path: '/'
    });
    this.route('news-item', {
      path: '/article/:news_id'
    });
    this.route('albums');
    this.route('collection', {
      path: '/albums/collection/:collection_slug'
    });
    this.route('albums-genre', {
      path: '/albums/genre/:genre_slug'
    });
    this.route('album', {
      path: '/music/:album_id'
    });
    this.route('books');
    this.route('books-genre', {
      path: '/books/genre/:genre_slug'
    });
    this.route('book', {
      path: '/books/:book_id'
    });
    this.route('designs');
    this.route('design', {
      path: '/designs/:design_id'
    });
    this.route('design-gallery', {
      path: '/design-gallery/:design_id/asset/:design_asset_id'
    });
    this.route('authors', function () {
      this.route('index', {
        path: '/'
      });
      this.route('starting-with', {
        path: '/i/:initial'
      });
    });
    this.route('author', {
      path: '/authors/:author_slug'
    });
    this.route('artists', function () {
      this.route('index', {
        path: '/'
      });
      this.route('starting-with', {
        path: '/i/:initial'
      });
    });
    this.route('artist', {
      path: '/artists/:artist_slug'
    });
    this.route('contact');
    this.route('about');
    this.route('newsletter');
    this.route('impressum');
    this.route('search', {
      path: '/search/:q'
    });
    this.route('spotify');
    this.route('todayweescape');
    this.route('mww'); // Catch-all route.

    this.route('not-found', {
      path: '/*'
    });
  });
  var _default = Router;
  _exports.default = _default;
});