define("frontend/templates/designs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EzTs+05H",
    "block": "{\"symbols\":[\"design\"],\"statements\":[[7,\"h2\",true],[8],[0,\"Design\"],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"\\n  Eine Auswahl an unterschiedlichen Designs, die von uns konzipiert und gestaltet wurden. Jede Kategorie enthält\\n  zahlreiche Designs.\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"designs\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[5,\"design-card\",[],[[\"@class\",\"@model\"],[\"design-card\",[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/designs.hbs"
    }
  });

  _exports.default = _default;
});