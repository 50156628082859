define("frontend/components/site-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    q: '',
    results: null,
    router: Ember.inject.service(),
    search: Ember.inject.service('site-search'),
    albums: Ember.computed('results', function () {
      let res = this.results;

      if (res) {
        let a = Ember.A();
        a.addObjects(res.albums);

        if (res.albumsByGenre) {
          a.addObjects(res.albumsByGenre);
        }

        return a.uniqBy('id').sortBy('releasedate').reverseObjects().slice(0, 3);
      }

      return null;
    }),
    books: Ember.computed('results', function () {
      let res = this.results;

      if (res) {
        let a = Ember.A();
        a.addObjects(res.books);

        if (res.booksByGenre) {
          a.addObjects(res.booksByGenre);
        }

        return a.uniqBy('id').sortBy('releasedate').reverseObjects().slice(0, 3);
      }

      return null;
    }),
    news: Ember.computed('results', function () {
      let res = this.results;

      if (res) {
        return res.news.slice(0, 3);
      }

      return null;
    }),
    actions: {
      closeSearch() {
        this.toggleSearch();
        var current = this.$(window).scrollTop();
        this.$(window).scroll(function () {
          this.$(window).scrollTop(current);
        });
        this.$(window).off('scroll');
      },

      handleFilterEntry() {
        let filterInputValue = this.q;
        this.search.filter(filterInputValue).then(filterResults => {
          if (filterResults.query === this.q) {
            this.set('results', filterResults.results);
          }
        });
      },

      submit() {
        let filterInputValue = this.q;
        this.router.transitionTo('search', {
          q: filterInputValue
        });
      }

    }
  });

  _exports.default = _default;
});