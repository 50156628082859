define("frontend/templates/components/news-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FOhM75Wp",
    "block": "{\"symbols\":[\"card\",\"title\",\"text\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"news-item\"],[8],[0,\"\\n  \"],[5,\"paper-card\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"news-item\",[22,\"model\"]]],{\"statements\":[[0,\"\\n      \"],[6,[23,1,[\"title\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[6,[23,2,[\"text\"]],[],[[],[]],{\"statements\":[[0,\"\\n          \"],[6,[23,3,[\"subhead\"]],[],[[],[]],{\"statements\":[[1,[24,[\"model\",\"subtitle\"]],false]],\"parameters\":[]}],[0,\"\\n          \"],[6,[23,3,[\"headline\"]],[],[[],[]],{\"statements\":[[1,[24,[\"model\",\"title\"]],false]],\"parameters\":[]}],[0,\"\\n        \"]],\"parameters\":[3]}],[0,\"\\n      \"]],\"parameters\":[2]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[6,[23,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"news-content\"],[8],[1,[24,[\"model\",\"content\"]],true],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"category\"]]],null,{\"statements\":[[0,\"        \"],[7,\"h3\",true],[8],[7,\"span\",true],[8],[0,\"Kategorie:\"],[9],[0,\" \"],[1,[24,[\"model\",\"category\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,4],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/news-card.hbs"
    }
  });

  _exports.default = _default;
});