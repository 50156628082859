define("frontend/templates/author", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NDzHGZdU",
    "block": "{\"symbols\":[\"book\"],\"statements\":[[5,\"books-nav\",[],[[],[]]],[0,\"\\n\\n\"],[7,\"h2\",true],[8],[0,\"Ebooks - \"],[1,[24,[\"author\",\"name\"]],false],[9],[0,\"\\n\\n\"],[5,\"creator-card\",[],[[\"@model\"],[[22,\"author\"]]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"books\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"author\",\"books\"]]],null,{\"statements\":[[0,\"    \"],[5,\"book-card\",[],[[\"@model\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/author.hbs"
    }
  });

  _exports.default = _default;
});