define("frontend/templates/application-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cNQsNRX3",
    "block": "{\"symbols\":[],\"statements\":[[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"application-loading\"],[8],[7,\"p\",true],[8],[0,\"Bitte warten… FUEGO wird geladen…\"],[9],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/application-loading.hbs"
    }
  });

  _exports.default = _default;
});