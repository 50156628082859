define("frontend/controllers/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    albums: Ember.computed('model', function () {
      let m = this.model;
      let a = Ember.A();
      a.addObjects(m.albumsByArtist);
      a.addObjects(m.albumsByTitle);
      a.addObjects(m.albumsByDescription);
      a.addObjects(m.albumsByGenre);
      return a.uniqBy('id').sortBy('releasedate').reverseObjects();
    }),
    books: Ember.computed('model', function () {
      let m = this.model;
      let b = Ember.A();
      b.addObjects(m.booksByAuthor);
      b.addObjects(m.booksByTitle);
      b.addObjects(m.booksBySubtitle);
      b.addObjects(m.booksByDescription);
      b.addObjects(m.booksByGenre);
      return b.uniqBy('id').sortBy('releasedate').reverseObjects();
    }),
    news: Ember.computed('model', function () {
      let m = this.model;
      let n = Ember.A();
      n.addObjects(m.newsByTitle);
      n.addObjects(m.newsBySubtitle);
      n.addObjects(m.newsByCategory);
      n.addObjects(m.newsByContent);
      return n.uniqBy('id').sortBy('date').reverseObjects();
    })
  });

  _exports.default = _default;
});