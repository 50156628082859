define("frontend/components/audio-player", ["exports", "amplitudejs"], function (_exports, _amplitudejs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['audio-player', 'flex-gt-sm-75'],

    didInsertElement() {
      this._super(...arguments);

      var ampSongs = [];
      this.songs.then(function (songs) {
        songs.forEach(function (value) {
          // console.debug(value.get('name'));
          // console.debug(value.get('url'));
          ampSongs.push({
            url: value.get('audioUrl'),
            name: value.get('name'),
            artist: value.get('artist'),
            album: value.get('albumName'),
            cover_art_url: value.get('coverUrl'),
            numberIndex: value.get('numberIndex')
          });
        }); // console.debug(ampSongs);

        _amplitudejs.default.init({
          "songs": ampSongs
        });
      });
    },

    willDestroyElement() {
      _amplitudejs.default.pause();

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});