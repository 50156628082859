define("frontend/models/artist", ["exports", "@ember-data/model", "frontend/config/environment"], function (_exports, _model, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)(),
    slug: (0, _model.attr)(),
    image: (0, _model.attr)(),
    biographyDe: (0, _model.attr)(),
    biographyEn: (0, _model.attr)(),
    url: (0, _model.attr)(),
    albums: (0, _model.hasMany)('album'),
    imageUrl: Ember.computed('image', function () {
      return _environment.default.APP.assetPrefix + '/images/music_artists/' + this.image;
    })
  });

  _exports.default = _default;
});