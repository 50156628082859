define("frontend/components/books-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['books-nav'],
    router: Ember.inject.service(),
    g: Ember.inject.service('genre-provider'),
    genres: Ember.computed('g', function () {
      return this.g.bookGenres();
    }),
    currentGenre: null,
    indices: function () {
      return [{
        key: 'ALL',
        name: 'Alle Autoren'
      }, {
        key: 'A',
        name: 'A'
      }, {
        key: 'B',
        name: 'B'
      }, {
        key: 'C',
        name: 'C'
      }, {
        key: 'D',
        name: 'D'
      }, {
        key: 'E',
        name: 'E'
      }, {
        key: 'F',
        name: 'F'
      }, {
        key: 'G',
        name: 'G'
      }, {
        key: 'H',
        name: 'H'
      }, {
        key: 'I',
        name: 'I'
      }, {
        key: 'J',
        name: 'J'
      }, {
        key: 'K',
        name: 'K'
      }, {
        key: 'L',
        name: 'L'
      }, {
        key: 'M',
        name: 'M'
      }, {
        key: 'N',
        name: 'N'
      }, {
        key: 'O',
        name: 'O'
      }, {
        key: 'P',
        name: 'P'
      }, {
        key: 'Q',
        name: 'Q'
      }, {
        key: 'R',
        name: 'R'
      }, {
        key: 'S',
        name: 'S'
      }, {
        key: 'T',
        name: 'T'
      }, {
        key: 'U',
        name: 'U'
      }, {
        key: 'V',
        name: 'V'
      }, {
        key: 'W',
        name: 'W'
      }, {
        key: 'X',
        name: 'X'
      }, {
        key: 'Y',
        name: 'Y'
      }, {
        key: 'Z',
        name: 'Z'
      }];
    }(),
    currentIndex: null,
    actions: {
      transitionToGenre(genre) {
        this.set('currentGenre', genre);
        this.router.transitionTo('books-genre', genre.get('slug'));
      },

      transitionToIndex(index) {
        this.set('currentIndex', index);

        if (index.key === 'ALL') {
          this.router.transitionTo('authors');
        } else {
          this.router.transitionTo('authors.starting-with', index.key);
        }
      }

    }
  });

  _exports.default = _default;
});