define("frontend/routes/design-gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fullScreen: Ember.inject.service(),

    model(params) {
      return Ember.RSVP.hash({
        currentAsset: this.store.findRecord('design-asset', params.design_asset_id),
        design: this.store.findRecord('design', params.design_id)
      });
    },

    hideNavigation: Ember.on('activate', function () {
      this.fullScreen.set('hasLayout', false);
    }),

    setupController(controller, model) {
      this._super(controller, model);

      let currentAsset = model.currentAsset;
      let assets = model.design.get('assets');
      let length = assets.get('length');
      let indexOfCurrentAsset = assets.indexOf(currentAsset);
      let previousAssetIndex = indexOfCurrentAsset - 1 < 0 ? length - 1 : indexOfCurrentAsset - 1;
      let nextAssetIndex = indexOfCurrentAsset + 1 >= length ? 0 : indexOfCurrentAsset + 1;
      let previousAsset = assets.objectAt(previousAssetIndex);
      let nextAsset = assets.objectAt(nextAssetIndex);
      controller.set('previousAssetId', previousAsset.get('id'));
      controller.set('nextAssetId', nextAsset.get('id'));
    },

    actions: {
      willTransition(transition) {
        if (transition.targetName !== this.routeName) {
          this.fullScreen.set('hasLayout', true);
        }
      }

    }
  });

  _exports.default = _default;
});