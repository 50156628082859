define("frontend/serializers/design-asset", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    attrs: {
      videoUrl: 'videoURL'
    },

    keyForAttribute(attr) {
      return attr;
    },

    keyForRelationship(key) {
      return key;
    }

  });

  _exports.default = _default;
});