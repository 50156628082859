define("frontend/models/hero", ["exports", "@ember-data/model", "frontend/config/environment"], function (_exports, _model, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    fuegoId: (0, _model.attr)(),
    url: (0, _model.attr)(),
    hero: (0, _model.attr)(),
    heroUrl: Ember.computed('hero', function () {
      return _environment.default.APP.apiHost + '/images/hero_banner/' + this.hero;
    })
  });

  _exports.default = _default;
});