define("frontend/routes/books-genre", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.store.query('genre-book', {
        slug: params.genre_slug
      });
    }

  });

  _exports.default = _default;
});