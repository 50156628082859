define("frontend/controllers/design-gallery", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    nextAssetId: null,
    previousAssetId: null,
    bindResizeEvent: Ember.on('init', function () {
      (0, _jquery.default)(window).on('resize', Ember.run.bind(this, this.updateYouTubeSize));
    }),
    actions: {
      ytPlaying() {},

      ytPaused() {},

      ytEnded() {// here you could load another video by changing the youTubeId
      },

      ytBuffering() {},

      ytReady() {
        this.updateYouTubeSize();
      }

    },

    updateYouTubeSize() {
      let container = (0, _jquery.default)('.current-video');
      let player = (0, _jquery.default)('.current-video .EmberYoutube-player');
      let innerPlayer = (0, _jquery.default)('.current-video .video-stream');
      let w = container.width();
      let h = container.height();
      let metadataHeight = (0, _jquery.default)('.metadata').height();
      let usableHeight = h - metadataHeight;

      if (w > usableHeight * 1.77 * 0.823) {
        let width = usableHeight * 1.77;
        player.css('padding-top', 0);
        player.width(width);
        player.height(usableHeight);
        innerPlayer.width(width);
        innerPlayer.height(usableHeight);
      } else {
        let height = w / 1.77;
        player.css('padding-top', (h - height) / 2);
        player.width(w);
        player.height(height);
        innerPlayer.width(w);
        innerPlayer.height(height);
      }
    }

  });

  _exports.default = _default;
});