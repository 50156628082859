define("frontend/templates/news-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kae9UX4V",
    "block": "{\"symbols\":[],\"statements\":[[5,\"news-card\",[],[[\"@model\"],[[22,\"model\"]]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/news-item.hbs"
    }
  });

  _exports.default = _default;
});