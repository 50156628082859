define("frontend/routes/authors/starting-with", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.store.query('author', {
        name: params.initial,
        include: 'books'
      });
    }

  });

  _exports.default = _default;
});