define("frontend/templates/components/design-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uz5z/4Aw",
    "block": "{\"symbols\":[\"card\",\"title\",\"text\",\"&default\"],\"statements\":[[5,\"paper-card\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"design\",[22,\"model\"]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"fa-icon\",[[24,[\"model\",\"iconName\"]]],[[\"prefix\",\"size\"],[[24,[\"model\",\"iconPrefix\"]],\"10x\"]]],false],[0,\"\\n    \"],[6,[23,1,[\"title\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,2,[\"text\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[6,[23,3,[\"headline\"]],[],[[],[]],{\"statements\":[[1,[24,[\"model\",\"title\"]],false]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[3]}],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"design-content\"],[8],[0,\"\\n      \"],[7,\"p\",true],[8],[1,[24,[\"model\",\"subtitle\"]],true],[9],[0,\"\\n      \"],[1,[24,[\"model\",\"content\"]],true],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[14,4],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/design-card.hbs"
    }
  });

  _exports.default = _default;
});