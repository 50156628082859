define("frontend/templates/artist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UMEZNU9l",
    "block": "{\"symbols\":[\"currentArtist\",\"album\"],\"statements\":[[5,\"albums-nav\",[],[[],[]]],[0,\"\\n\\n\"],[7,\"h2\",true],[8],[0,\"Musik - \"],[1,[24,[\"artist\",\"name\"]],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"not-eq\",[[24,[\"artist\",\"name\"]],\"Various Artists\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"creator-card\",[],[[\"@model\"],[[22,\"artist\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"albums\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[4,\"each\",[[23,1,[\"albums\"]]],null,{\"statements\":[[0,\"      \"],[5,\"album-card\",[],[[\"@model\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/artist.hbs"
    }
  });

  _exports.default = _default;
});