define("frontend/models/customer", ["exports", "@ember-data/model", "frontend/mixins/model-validator"], function (_exports, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_modelValidator.default, {
    email: (0, _model.attr)(),
    name: (0, _model.attr)(),
    isSubscriber: (0, _model.attr)('boolean'),
    tickets: (0, _model.hasMany)('customer-ticket'),
    // Used in newsletter form.
    isAgreementFulfilled: (0, _model.attr)('boolean'),

    init() {
      this._super(...arguments);

      this.validations = {
        email: {
          email: {
            email: true,
            message: 'muss gültig sein'
          },
          presence: {
            presence: true,
            message: 'darf nicht leer sein'
          }
        },
        name: {
          presence: {
            presence: true,
            message: 'darf nicht leer sein'
          },
          length: {
            minimum: {
              value: 4,
              message: 'ist zu kurz (mindestens {value} Zeichen)'
            }
          }
        },
        isAgreementFulfilled: {
          acceptance: {
            acceptance: true,
            message: 'muss akzeptiert werden'
          }
        }
      };
    }

  });

  _exports.default = _default;
});