define("frontend/models/shop-url-album", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    assetMap: Ember.inject.service(),
    url: (0, _model.attr)(),
    iconName: (0, _model.attr)(),
    album: (0, _model.belongsTo)('album'),
    badgeUrl: Ember.computed('iconName', function () {
      return '/' + this.assetMap.resolve(`images/badges/${this.iconName}`);
    })
  });

  _exports.default = _default;
});