define("frontend/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5M1rZfs7",
    "block": "{\"symbols\":[\"book\",\"album\",\"&default\"],\"statements\":[[7,\"h1\",true],[8],[0,\"Oops… Seite nicht gefunden!\"],[9],[0,\"\\n\"],[7,\"h2\",true],[8],[0,\"Besondere Empfehlungen:\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"albums\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h2\",true],[8],[0,\"Musik\"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"albums layout-row layout-wrap\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"albums\"]]],null,{\"statements\":[[0,\"      \"],[5,\"album-card\",[],[[\"@model\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"books\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h2\",true],[8],[0,\"Ebooks\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"model\",\"books\"]]],null,{\"statements\":[[0,\"    \"],[5,\"book-card\",[],[[\"@model\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});