define("frontend/templates/components/infinity-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r7dkLYOW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[24,[\"infinityModelContent\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isDoneLoading\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[1,[22,\"loadedText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[1,[22,\"loadingText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/infinity-loader.hbs"
    }
  });

  _exports.default = _default;
});