define("frontend/models/youtube-url-book", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    url: (0, _model.attr)(),
    youtubeId: (0, _model.attr)(),
    iconName: (0, _model.attr)(),
    book: (0, _model.belongsTo)('book')
  });

  _exports.default = _default;
});