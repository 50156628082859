define("frontend/templates/authors/starting-with", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1tf0Fyf/",
    "block": "{\"symbols\":[\"author\"],\"statements\":[[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[5,\"creator-card-list\",[],[[\"@model\",\"@creatorType\"],[[23,1,[]],\"author\"]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/authors/starting-with.hbs"
    }
  });

  _exports.default = _default;
});