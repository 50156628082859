define("frontend/initializers/model-locale", ["exports", "ember-model-validator/initializers/model-locale"], function (_exports, _modelLocale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _modelLocale.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _modelLocale.initialize;
    }
  });
});