define("frontend/templates/design", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6impozSA",
    "block": "{\"symbols\":[\"asset\",\"card\",\"title\",\"text\"],\"statements\":[[7,\"h2\",true],[8],[1,[24,[\"model\",\"title\"]],false],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"design-assets\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"sortedAssets\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"design-assets-card\"],[8],[0,\"\\n      \"],[5,\"paper-card\",[],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"a\",true],[11,\"href\",[29,[\"/design-gallery/\",[24,[\"model\",\"id\"]],\"/asset/\",[23,1,[\"id\"]]]]],[8],[0,\"\\n          \"],[7,\"img\",true],[11,\"src\",[23,1,[\"assetUrl\"]]],[10,\"class\",\"cover\"],[10,\"width\",\"230\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[6,[23,2,[\"title\"]],[],[[],[]],{\"statements\":[[0,\"\\n          \"],[6,[23,3,[\"text\"]],[],[[],[]],{\"statements\":[[0,\"\\n            \"],[6,[23,4,[\"headline\"]],[],[[],[]],{\"statements\":[[1,[23,1,[\"title\"]],false]],\"parameters\":[]}],[0,\"\\n          \"]],\"parameters\":[4]}],[0,\"\\n        \"]],\"parameters\":[3]}],[0,\"\\n\\n        \"],[6,[23,2,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"design-asset-content\"],[8],[0,\"\\n            \"],[1,[23,1,[\"content\"]],true],[0,\"\\n          \"],[9],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[2]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/design.hbs"
    }
  });

  _exports.default = _default;
});