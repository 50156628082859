define("frontend/templates/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j079ojVz",
    "block": "{\"symbols\":[\"news-item\",\"book\",\"album\",\"&default\"],\"statements\":[[7,\"h1\",true],[8],[0,\"Suche \"],[1,[22,\"q\"],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"albums\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h2\",true],[8],[0,\"Musik\"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"albums layout-row layout-wrap\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"albums\"]]],null,{\"statements\":[[0,\"      \"],[5,\"album-card\",[],[[\"@model\"],[[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"books\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h2\",true],[8],[0,\"Ebooks\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"books\"]]],null,{\"statements\":[[0,\"    \"],[5,\"book-card\",[],[[\"@model\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"news\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h2\",true],[8],[0,\"News\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"news\"]]],null,{\"statements\":[[0,\"    \"],[5,\"news-card\",[],[[\"@model\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[28,\"and\",[[28,\"and\",[[24,[\"albums\"]],[24,[\"books\"]]],null],[24,[\"news\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[0,\"\\n    Ihre Suche erzielte keine Treffer. Versuchen Sie es mit einem anderen Suchbegriff, z. B. \"],[7,\"em\",true],[8],[0,\"Berlin\"],[9],[0,\".\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,4],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/search.hbs"
    }
  });

  _exports.default = _default;
});