define("frontend/templates/albums", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fO5OOrap",
    "block": "{\"symbols\":[\"album\"],\"statements\":[[5,\"albums-nav\",[],[[],[]]],[0,\"\\n\\n\"],[7,\"h2\",true],[8],[0,\"Musik\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"albums\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[5,\"album-card\",[],[[\"@model\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[5,\"infinity-loader\",[],[[\"@infinityModel\"],[[22,\"model\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/albums.hbs"
    }
  });

  _exports.default = _default;
});