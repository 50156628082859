define("frontend/adapters/application", ["exports", "@ember-data/adapter/json-api", "frontend/config/environment"], function (_exports, _jsonApi, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    host: _environment.default.APP.apiHost,
    namespace: 'api'
  });

  _exports.default = _default;
});