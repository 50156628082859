define("frontend/routes/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        albums: this.store.findAll('album').then(function (result) {
          return result.slice(0, 3);
        }),
        books: this.store.findAll('book').then(function (result) {
          return result.slice(0, 3);
        })
      });
    }

  });

  _exports.default = _default;
});