define("frontend/routes/collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.store.query('album', {
        collectionSlug: params.collection_slug,
        include: 'artist,tracks'
      });
    }

  });

  _exports.default = _default;
});