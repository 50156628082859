define("frontend/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.store.query('hero', {
        isPublished: true
      });
    }

  });

  _exports.default = _default;
});