define("frontend/models/album", ["exports", "@ember-data/model", "frontend/config/environment"], function (_exports, _model, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    ean: (0, _model.attr)(),
    title: (0, _model.attr)(),
    description: (0, _model.attr)(),
    collection: (0, _model.attr)(),
    releasedate: (0, _model.attr)(),
    genres: (0, _model.hasMany)('genre-album'),
    fuegoId: (0, _model.attr)(),
    cleanedUpFuegoId: (0, _model.attr)(),
    slug: (0, _model.attr)(),
    collectionSlug: (0, _model.attr)(),
    criticReview: (0, _model.attr)(),
    typeId: (0, _model.attr)(),
    linerNotes: (0, _model.attr)(),
    artistName: (0, _model.attr)(),
    artist: (0, _model.belongsTo)('artist'),
    shopUrls: (0, _model.hasMany)('shop-url-album'),
    youtubeUrls: (0, _model.hasMany)('youtube-url-album'),
    coverUrl: Ember.computed('cleanedUpFuegoId', function () {
      return _environment.default.APP.assetPrefix + '/images/music_cover/' + parseInt(this.cleanedUpFuegoId, 10) + '_600.jpg';
    }),
    coverTitle: Ember.computed('title', function () {
      return this.title + ' Cover';
    }),
    tracks: (0, _model.hasMany)('album-track')
  });

  _exports.default = _default;
});