define("frontend/templates/components/creator-card-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mpl5goab",
    "block": "{\"symbols\":[\"card\",\"title\",\"text\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[5,\"paper-card\",[],[[\"@class\"],[\"creator-card\"]],{\"statements\":[[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[[22,\"creatorType\"],[24,[\"model\",\"slug\"]]]],{\"statements\":[[0,\"\\n      \"],[6,[23,1,[\"image\"]],[],[[\"@src\",\"@alt\",\"@title\"],[[24,[\"model\",\"imageUrl\"]],[24,[\"model\",\"name\"]],[24,[\"model\",\"name\"]]]]],[0,\"\\n      \"],[6,[23,1,[\"title\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[6,[23,2,[\"text\"]],[],[[],[]],{\"statements\":[[0,\"\\n          \"],[6,[23,3,[\"headline\"]],[],[[],[]],{\"statements\":[[1,[24,[\"model\",\"name\"]],false]],\"parameters\":[]}],[0,\"\\n        \"]],\"parameters\":[3]}],[0,\"\\n      \"]],\"parameters\":[2]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,4],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/creator-card-list.hbs"
    }
  });

  _exports.default = _default;
});