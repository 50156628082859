define("frontend/templates/books", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hByOn1sX",
    "block": "{\"symbols\":[\"book\"],\"statements\":[[5,\"books-nav\",[],[[],[]]],[0,\"\\n\\n\"],[7,\"h2\",true],[8],[0,\"Ebooks\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[5,\"book-card\",[],[[\"@model\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[5,\"infinity-loader\",[],[[\"@infinityModel\"],[[22,\"model\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/books.hbs"
    }
  });

  _exports.default = _default;
});