define("frontend/models/book", ["exports", "@ember-data/model", "frontend/config/environment"], function (_exports, _model, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    ean: (0, _model.attr)(),
    title: (0, _model.attr)(),
    description: (0, _model.attr)(),
    collection: (0, _model.attr)(),
    releaseDate: (0, _model.attr)(),
    genres: (0, _model.hasMany)('genre-book'),
    fuegoId: (0, _model.attr)(),
    cleanedUpFuegoId: (0, _model.attr)(),
    slug: (0, _model.attr)(),
    collectionSlug: (0, _model.attr)(),
    criticReview: (0, _model.attr)(),
    subtitle: (0, _model.attr)(),
    isbn: (0, _model.attr)(),
    price: (0, _model.attr)(),
    format: (0, _model.attr)(),
    author: (0, _model.belongsTo)('author'),
    shopUrls: (0, _model.hasMany)('shop-url-book'),
    youtubeUrls: (0, _model.hasMany)('youtube-url-book'),
    coverUrl: Ember.computed('cleanedUpFuegoId', function () {
      return _environment.default.APP.assetPrefix + '/images/book_cover/' + this.cleanedUpFuegoId + '_600.jpg';
    }),
    coverTitle: Ember.computed('title', function () {
      return this.title + ' Cover';
    }),
    shortDescription: Ember.computed('description', function () {
      let n = 500;
      let text = this.description;
      let short = text.substr(0, n);

      if (/^\S/.test(text.substr(n))) {
        return short.replace(/\s+\S*$/, "") + '…';
      }

      return short + '…';
    })
  });

  _exports.default = _default;
});