define("frontend/routes/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let customer = this.store.createRecord('customer');
      let ticket = this.store.createRecord('customer-ticket');
      ticket.set('customer', customer);
      return ticket;
    }

  });

  _exports.default = _default;
});