define("frontend/templates/components/albums-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "459BLRLE",
    "block": "{\"symbols\":[\"menu\",\"content\",\"genre\",\"menu\",\"content\",\"index\",\"&default\"],\"statements\":[[5,\"paper-menu\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,4,[\"trigger\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"paper-button\",[],[[\"@raised\"],[true]],{\"statements\":[[0,\"\\n      Künstler\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,4,[\"content\"]],[],[[\"@width\"],[4]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"indices\"]]],null,{\"statements\":[[0,\"      \"],[6,[23,5,[\"menu-item\"]],[],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],\"transitionToIndex\",[23,6,[]]],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"span\",true],[8],[1,[23,6,[\"name\"]],false],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[6]},null],[0,\"  \"]],\"parameters\":[5]}],[0,\"\\n\"]],\"parameters\":[4]}],[0,\"\\n\\n\"],[5,\"paper-menu\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"trigger\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"paper-button\",[],[[\"@raised\"],[true]],{\"statements\":[[0,\"\\n      Genres\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"genres\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[\"content\"]],[],[[\"@width\"],[4]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"genres\"]]],null,{\"statements\":[[0,\"      \"],[6,[23,2,[\"menu-item\"]],[],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],\"transitionToGenre\",[23,3,[]]],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"span\",true],[8],[1,[23,3,[\"name\"]],false],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]}],[0,\"\\n\\n\"],[14,7],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/albums-nav.hbs"
    }
  });

  _exports.default = _default;
});