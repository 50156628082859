define("frontend/templates/albums-genre", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7kosG5SW",
    "block": "{\"symbols\":[\"album\"],\"statements\":[[5,\"albums-nav\",[],[[],[]]],[0,\"\\n\\n\"],[7,\"h2\",true],[8],[0,\"Musik - \"],[1,[24,[\"model\",\"firstObject\",\"name\"]],false],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"albums\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"firstObject\",\"albums\"]]],null,{\"statements\":[[0,\"    \"],[5,\"album-card\",[],[[\"@model\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/albums-genre.hbs"
    }
  });

  _exports.default = _default;
});