define("frontend/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    // Add your transitions here, like:
    //   this.transition(
    //     this.fromRoute('people.index'),
    //     this.toRoute('people.detail'),
    //     this.use('toLeft'),
    //     this.reverse('toRight')
    //   );
    this.transition(this.hasClass('liquid-search-container'), // this makes our rule apply when the liquid-if transitions to the
    // true state.
    this.toValue(true), // this.use('toDown')
    // this.debug()
    this.use('focusAfter', 'toDown') // this.reverse('focusAfter', 'toUp'),
    // which means we can also apply a reverse rule for transitions to
    // the false state.
    // this.reverse('toLeft', {duration})
    );
    this.transition(this.hasClass('liquid-search-overlay'), // this makes our rule apply when the liquid-if transitions to the
    // true state.
    this.toValue(true), this.use('fade') // this.debug()
    // this.use('focusAfter', 'toDown')
    // this.reverse('focusAfter', 'toUp'),
    // which means we can also apply a reverse rule for transitions to
    // the false state.
    // this.reverse('toLeft', {duration})
    );
  }
});