define("frontend/routes/albums", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    infinity: Ember.inject.service(),

    model() {
      return this.infinity.model("album", {
        perPage: 30,
        startingPage: 1,
        perPageParam: "itemsPerPage",
        pageParam: "page",
        totalPagesParam: "meta.totalItems"
      });
    }

  });

  _exports.default = _default;
});