define("frontend/models/custom-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const inflector = _emberInflector.default.inflector;
  inflector.irregular('hero', 'heroes'); // Meet Ember Inspector's expectation of an export

  var _default = {};
  _exports.default = _default;
});