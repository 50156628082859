define("frontend/services/genre-provider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    albumGenres() {
      return this.store.findAll('genre-album');
    },

    bookGenres() {
      return this.store.findAll('genre-book');
    }

  });

  _exports.default = _default;
});