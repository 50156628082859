define("frontend/models/customer-ticket", ["exports", "@ember-data/model", "frontend/mixins/model-validator"], function (_exports, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_modelValidator.default, {
    message: (0, _model.attr)(),
    customer: (0, _model.belongsTo)('customer'),
    _internal_customer_name: (0, _model.attr)(),
    _internal_customer_email: (0, _model.attr)(),

    init() {
      this._super(...arguments);

      this.validations = {
        message: {
          presence: {
            presence: true,
            message: 'darf nicht leer sein'
          },
          length: {
            minimum: {
              value: 20,
              message: 'ist zu kurz (mindestens {value} Zeichen)'
            },
            maximum: {
              value: 400,
              message: 'ist zu lang (maximal {value} Zeichen)'
            }
          }
        },
        customer: {
          relations: ['belongsTo']
        }
      };
    }

  });

  _exports.default = _default;
});